
/* ----------------------------------------------------------------

	topbar.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/


#top-bar {
	position: relative;
	border-bottom: $topbar-border-size solid $topbar-border-color;
	font-size: 0.815rem;
	background-color: $topbar-bg;
}


/* Top Links
---------------------------------*/

.top-links {
	position: relative;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-ms-flex-positive: 0;
    flex-grow: 0;
    border-bottom: $topbar-border-size solid $topbar-border-color;
    @include media-breakpoint-up(md){
    	border-bottom: 0;
    }
	&-container {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		list-style: none;
		margin-bottom: 0;
	}

	&-item {
		position: relative;
		border-left: $topbar-border-size solid $topbar-border-color;

		&:first-child,
		.top-links-sub-menu & { border-left: 0 !important; }

		> a {
			display: block;
			padding: $topbar-text-padding;
			font-size: $topbar-font-size;
			line-height: $topbar-line-font-l-height;
			font-weight: $topbar-font-weight;
			text-transform: $topbar-font-transform;
			color: $topbar-font-color;

			> i { vertical-align: top;
				&.icon-angle-down { margin: 0 0 0 5px !important; }
				&:first-child { margin-right: 3px; }
			}
		}

		&.full-icon > a > i {
			top: 2px;
			font-size: 0.875rem;
			margin: 0;
		}

		&:hover { background-color: #EEE; }

	}

	&-sub-menu,
	&-section {
		position: absolute;
		visibility: hidden;
		pointer-events: none;
		opacity: 0;
		list-style: none;
		z-index: -1;
		line-height: 1.5;
		background: $topbar-bg;
		border: 0;
		top: 100%;
		left: -1px;
		width: 140px;
		margin-top: 10px;
		border: $topbar-border-size  solid $topbar-border-color;
		border-top: 1px solid $theme-color;
		box-shadow: 0px 13px 42px 11px rgba(0, 0, 0, 0.05);
		transition: opacity .25s ease, margin .2s ease;
	}

	&:not(.on-click) .top-links-item:hover > .top-links-sub-menu,
	&:not(.on-click) .top-links-item:hover > .top-links-section,
	&.on-click .top-links-sub-menu,
	&.on-click .top-links-section {
		opacity: 1;
		visibility: visible;
		margin-top: 0;
		pointer-events: auto;
		z-index: 499;
	}

	&.on-click .top-links-sub-menu,
	&.on-click .top-links-section {
		display: none;
	}

	&-sub-menu {
		.top-links-sub-menu {
			top: -$topbar-border-size;
			left: 100%;
		}
		.top-links-item {
			&:not(:first-child) {
				border-top: $topbar-border-size solid darken($topbar-bg, 3.92);
			}
			&:hover { background-color: darken($topbar-bg, 2.35); }
			> a {
				padding-top: 9px;
				padding-bottom: 9px;
				font-size: 0.6875rem;
				> img {
					display: inline-block;
					position: relative;
					top: -1px;
					width: 16px;
					height: 16px;
					margin-right: 4px;
				}

				i.icon-angle-down {
					margin: 0 !important;
					position: absolute;
					top: 50%;
					left: auto;
					right: 10px;
					transform: translateY(-50%) rotate(-90deg);
				}
			}
		}
		&.top-demo-lang .top-links-item > a > img {
			top: 4px;
			width: 16px;
			height: 16px;
		}
	}
	&-section {
		padding: 25px;
		left: 0;
		width: 280px;
		&.menu-pos-invert {
			left: auto;
			right: 0;
		}
	}
}


/* Top Social
-----------------------------------------------------------------*/

#top-social {
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	margin: 0;
	li {
		position: relative;
		list-style: none;
		border-left: $topbar-border-size solid $topbar-border-color;
		&:first-child { border-left: 0 !important; }
		.ts-icon,
		.ts-text {
			display: block;
			-ms-flex-preferred-size: 0;
			flex-basis: 0;
			-ms-flex-positive: 1;
			flex-grow: 1;
			height: $topbar-line-height;
			line-height: $topbar-line-height;
		}
		.ts-icon {
			width: 40px;
			text-align: center;
			font-size: 0.875rem;
		}
		.ts-text {
			max-width: 0;
			white-space: nowrap;
			-webkit-transition: all .2s ease;
			transition: all .2s ease;
		}
		a:hover .ts-text {
			max-width: 200px;
			padding-right: 12px;
			-webkit-transition: all .4s ease;
			transition: all .4s ease;
		}
	}
	a {
		display: -ms-flexbox;
		display: flex;
		width: auto;
		overflow: hidden;
		font-weight: bold;
		color: #666;
		&:hover {
			color: #FFF !important;
			text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
		}
	}
}


/* Top Login
-----------------------------------------------------------------*/

#top-login {
	margin-bottom: 0;
	.checkbox { margin-bottom: 10px; }

	.form-control {
		position: relative;
			&:focus { border-color: #CCC; }
	}

	.input-group#top-login-username { margin-bottom: -1px; }

	#top-login-username {
		input,
		.input-group-addon {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.input-group#top-login-password { margin-bottom: 10px; }

	#top-login-password input,
	#top-login-password .input-group-addon {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}