
/*-----------------------------------------------------------------------------------

	Shortcodes: clients.scss

-----------------------------------------------------------------------------------*/
$client-prefix: clients-grid;

.#{$client-prefix} {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	list-style: none;
	overflow: hidden;
	.grid-item {
		position: relative;
		padding: $clients-grid-padding 0;
		-webkit-backface-visibility: hidden;
		a {
			opacity: $clients-img-opacity;
			body:not(.device-touch) & {
				-webkit-transition: all .5s ease;
				-o-transition: all .5s ease;
				transition: all .5s ease;
			}
		}

		a,
		img {
			display: block;
			width: 75%;
			margin-left: auto;
			margin-right: auto;
		}

		img { width: 100%; }

		&::before,
		&::after {
			content: '';
			position: absolute;
		}

		&::before {
			height: 100%;
			top: 0;
			left: -$clients-grid-border-size;;
			border-left: $clients-grid-border-size $clients-grid-border-style lighten($text-color, 60%);
		}

		&::after {
			width: 100%;
			height: 0;
			top: auto;
			left: 0;
			bottom: -$clients-grid-border-size;;
			border-bottom: $clients-grid-border-size $clients-grid-border-style lighten($text-color, 60%);
		}
	}
}