
/* ----------------------------------------------------------------

	extras.scss

-----------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Bootstrap Specific
-----------------------------------------------------------------*/

.table {
	.success { color: #3c763d; }
	.info { color: #31708f; }
	.warning { color: #8a6d3b; }
	.danger { color: #a94442; }
}

.btn-link,
.page-link,
.page-link:hover,
.page-link:focus { color: $theme-color; }

.btn-link {
	&:hover,
	&:focus { color: #222; }
}

.boot-tabs { margin-bottom: 20px !important; }

.carousel-control {
	.icon-chevron-left,
	.icon-chevron-right {
		position: absolute;
		top: 47.5%;
		z-index: 5;
		display: inline-block;
	}
	.icon-chevron-left { left: 50%; }
	.icon-chevron-right { right: 50%; }
}

.responsive-utilities th small {
	display: block;
	font-weight: 400;
	color: #999;
}

.show-grid [class^=col-] {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #EEE;
	border: 1px solid #DDD;
}

.img-circle { border-radius: 50% !important; }

.page-item.active .page-link,
.page-link:hover,
.page-link:focus {
	color: #FFF !important;
	background-color: $theme-color !important;
	border-color: $theme-color !important;
}

.nav-pills {
	> li.active > {
		a,
		a:hover,
		a:focus { background-color: $theme-color; }
	}
}

label{
	&.radio,
	&.checkbox { padding-left: 20px; }
}

.input-group-lg > .input-group-addon { line-height: 1; }



/* ----------------------------------------------------------------
	Shape Dividers
-----------------------------------------------------------------*/

.shape-divider {
	position: absolute;
	top: -1px;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 1;

	&[data-position="bottom"] {
		top: auto;
		bottom: -1px;
	}

	&[data-front="true"] {
		z-index: 4;
	}

	svg {
		position: relative;
		width: 100%;
		width: calc( 100% + 1.3px );
		height: 50px;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
	}
	&[data-position="bottom"],
	&[data-flip-vertical="true"] {
		transform: rotate(180deg);
	}

	&[data-position="bottom"][data-flip-vertical="true"] {
		transform: rotate(0deg);
	}

	&[data-flip="true"] svg {
		transform: translateX(-50%) rotateY(180deg);
	}

	&-fill {
		fill: $body-bg;
		transform-origin: center;
		transform: rotateY(0deg);
	}
}


/* ----------------------------------------------------------------
	HTML5 Youtube Video Backgrounds
-----------------------------------------------------------------*/

.mb_YTVPlayer {
	display:block;
	transform:translateZ(0);
	transform-style: preserve-3d;
	perspective: 1000;
	backface-visibility: hidden;
	transform:translate3d(0,0,0);
	animation-timing-function: linear;
	&:focus { outline: 0; }
	&.fullscreen {
		display:block!important;
		position: fixed!important;
		width: 100%!important;
		height: 100%!important;
		top: 0!important;
		left: 0!important;
		margin: 0!important;
		border: none !important;
		.mbYTP_wrapper { opacity:1 !important; }
	}
}

.mbYTP_wrapper iframe { max-width: 4000px !important; }

.inline_YTPlayer {
	margin-bottom: 20px;
	vertical-align:top;
	position:relative;
	left:0;
	overflow: hidden;
	border-radius: 4px;
	-moz-box-shadow: 0 0 5px rgba(0,0,0,.7);
	-webkit-box-shadow: 0 0 5px rgba(0,0,0,.7);
	box-shadow: 0 0 5px rgba(0,0,0,.7);
	background: rgba(0,0,0,.5);
	img {
		border: none!important;
		-moz-transform: none!important;
		-webkit-transform: none!important;
		-o-transform: none!important;
		transform: none!important;
		margin:0!important;
		padding:0!important
	}
}


/* ----------------------------------------------------------------
	Datepicker Component
-----------------------------------------------------------------*/

.datepicker {
	td,
	th { padding: 5px; }
}

/* ----------------------------------------------------------------
	Star Ratings Component
-----------------------------------------------------------------*/

.rating {
	&-xl { font-size: 3rem !important; }
	&-lg { font-size: 2.5rem !important; }
	&-md { font-size: 2rem !important; }
	&-sm { font-size: 1.75rem !important; }
	&-xs { font-size: 1.25rem !important; }
	&-container .star { margin: 0 2px !important; }
	&-container .filled-stars {
		-webkit-text-stroke: 0px !important;
		text-shadow: none !important;
	}
}

/* ----------------------------------------------------------------
	Custom Animate.css Styles
-----------------------------------------------------------------*/

@include keyframes (fadeInDownSmall) {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInDownSmall {
	-webkit-animation-name: fadeInDownSmall;
	animation-name: fadeInDownSmall;
}


@include keyframes (fadeInLeftSmall) {
	from {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInLeftSmall {
	-webkit-animation-name: fadeInLeftSmall;
	animation-name: fadeInLeftSmall;
}


@include keyframes (fadeInRightSmall) {
	from {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInRightSmall {
	-webkit-animation-name: fadeInRightSmall;
	animation-name: fadeInRightSmall;
}


@include keyframes (fadeInUpSmall) {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInUpSmall {
	-webkit-animation-name: fadeInUpSmall;
	animation-name: fadeInUpSmall;
}


@include keyframes (fadeOutDownSmall) {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}
}

.fadeOutDownSmall {
	-webkit-animation-name: fadeOutDownSmall;
	animation-name: fadeOutDownSmall;
}


@include keyframes (fadeOutLeftSmall) {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}
}

.fadeOutLeftSmall {
	-webkit-animation-name: fadeOutLeftSmall;
	animation-name: fadeOutLeftSmall;
}


@include keyframes (fadeOutRightSmall) {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}
}

.fadeOutRightSmall {
	-webkit-animation-name: fadeOutRightSmall;
	animation-name: fadeOutRightSmall;
}

@include keyframes (fadeOutUpSmall) {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}
}

.fadeOutUpSmall {
	-webkit-animation-name: fadeOutUpSmall;
	animation-name: fadeOutUpSmall;
}