
/*-----------------------------------------------------------------------------------

	Shortcodes: process-steps.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Process Steps
-----------------------------------------------------------------*/
$psteps-prefix: process; //child

.#{$psteps-prefix}-steps {
	margin: 0 0 40px;
	list-style: none;
	li {
		position: relative;
		text-align: center;
		overflow: hidden;
		.i-circled { margin-bottom: 0 !important; }
		.i-bordered { background-color: #FFF; }
		&.active,
		&.ui-tabs-active {
			h5 {
				font-weight: 600;
				color: $theme-color;
			}
			a {
				background-color: $theme-color !important;
				color: #FFF;
				text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
				border-color: $theme-color !important;
			}
		}
		@include media-breakpoint-up(lg) {
			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 30px;
				left: 0;
				margin-left: -26px;
				width: 50%;
				border-top: 1px dashed #DDD;
			}
			&::after {
				left: auto;
				right: 0;
				margin: 0 -26px 0 0;
			}
			&:first-child::before,
			&:last-child::after { display: none; }
			h5 {
				margin: 15px 0 0 0;
				font-size: 1rem;
				font-weight: 300;
			}
		}
	}
}