
/* ----------------------------------------------------------------

	widgets.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/


.widget {
	position: relative;
	margin-top: $widget-margin;

	p { line-height: 1.7; }

	.sidebar-widgets-wrap & {
		padding-top: $widget-margin;
		border-top: $widget-border;
		&:first-child {
			padding-top: 0;
			border-top: 0;
		}
		> h4 { letter-spacing: $widget-title-letter-spacing + 1px; }
	}

	&:first-child { margin-top: 0; }

	> h4 {
		margin-bottom: $widget-title-margin;
		font-size: $widget-title-font-size;
		font-weight: $widget-title-font-weight;
		letter-spacing: $widget-title-letter-spacing;
		text-transform: $widget-title-text-transform;
	}

	/* Widget - Links
	-----------------------------------------------------------------*/
	&_nav_menu,
	&_links,
	&_meta,
	&_archive,
	&_recent_comments,
	&_recent_entries,
	&_categories,
	&_pages,
	&_rss {
		ul,
		li {
			list-style: none;
			margin: 0;
		}
		li {
			display: flex;
			padding: 4px 0;
			font-size: 15px;
			a {
				display: inline-block;
				padding: 0 3px 0 12px;
				border: none !important;
				color: $text-color;
				&:hover { color: #000; }
			}
		}
		&:not(.widget-li-noicon) li::before {
			content: $widget-icon;
			font-family: $widget-icon-font-family;
			display: inline-block;
			position: relative;
			top: 1px;
			left: 0;
		}
		&.widget-li-noicon li a {
			padding-left: 0;
		}
		> ul {
			margin-top: -4px !important;
			> li:first-child { border-top: 0 !important; }
		}
		ul ul { margin-left: 15px; }
	}
	&_recent_comments li::before {
		content: $widget-comment-icon;
	}

	/* Widget - Testimonial & Twitter
	-----------------------------------------------------------------*/

	.testimonial{
		&.no-image .testi-image { display: none; }

		&.twitter-scroll {
			.testi-image {
				margin-right: 10px;
				width: 28px;
				height: 28px;
				a,
				img,
				i {
					width: 28px;
					height: 28px;
				}
				i {
					background-color: darken($body-bg, 6.67);
					line-height: 28px;
					font-size: 0.875rem;
					color: lighten($text-color, 26.67);
				}
			}
		}

		&:not(.twitter-scroll) .testi-image,
		&:not(.twitter-scroll) .testi-image a,
		&:not(.twitter-scroll) .testi-image img {
			width: 42px;
			height: 42px;
		}

		p { font-size: 0.875rem; }
	}

}

@include media-breakpoint-only(md) {
	.sidebar-widgets-wrap {
		position: relative;
		display: -ms-flex;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -20px -40px -20px;
		.widget {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
			margin: 0 0 40px 0;
			padding-top: 0;
			padding-left: 20px;
			padding-right: 20px;
			border-top: 0;
		}
	}
}


/* Widget - Quick Contact Form
-----------------------------------------------------------------*/

.quick-contact-widget{
	&.form-widget {
		.form-control,
		.input-group,
		.sm-form-control { margin-bottom: 10px; }

		.form-control { margin-bottom: 0; }
	}
}

.quick-contact-widget.form-widget form,
#template-contactform { position: relative; }


/* Newsletter Widget
-----------------------------------------------------------------*/

.subscribe-widget h5 {
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 1.5;
}


/* Twitter Feed Widget
-----------------------------------------------------------------*/

.widget-twitter-feed {
	li { margin: 15px 0 0 0;
		&:first-child { margin-top: 0; }
	}
	small {
		display: block;
		margin-top: 3px;
	}
	a:not(:hover) { color: lighten($text-color, 33.33); }

	.twitter-feed:not(.twitter-feed-avatar) a.twitter-avatar,
	.twitter-feed.twitter-feed-avatar i.icon-twitter { display: none; }

	.twitter-feed-avatar { margin-left: 44px;
		> li a.twitter-avatar {
			display: block;
			position: absolute;
			left: -44px;
			text-align: center;
			top: 2px;
			width: 32px;
			height: 32px;
		}
	}

	a.twitter-avatar img { border-radius: 50%; }
}


/* Widget Filter Links
-----------------------------------------------------------------*/

.widget-filter-links {
	ul {
		list-style: none;
		margin-bottom: 0;
	}
	.widget-filter-reset {
		position: absolute;
		top: 0;
		left: auto;
		right: 0;
		font-size: 12px;
		line-height: 22px;
		a { color: red !important; }
		&.active-filter { display: none; }
	}

	&:not(:first-child) .widget-filter-reset { top: 50px; }

	li {
			position: relative;
			font-size: 15px;
			line-height: 24px;
			&:not(:first-child) { margin-top: 10px; }
			a {
				display: block;
				color: $text-color;
				font-family: $heading-font;
				font-weight: 500;
			}

			span {
				position: absolute;
				top: 1px;
				left: auto;
				right: 0;
				font-size: 11px;
				width: 22px;
				height: 22px;
				line-height: 22px;
				text-align: center;
				color: lighten($text-color, 20);
				background-color: darken($body-bg, 6.67);
				border-radius: 50%;
			}
			&:hover a { color: lighten($text-color, 20); }
			&.active-filter a { font-weight: 700; }
			&.active-filter span {
				color: #FFF !important;
				background-color: $theme-color !important;
				text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
			}
		}
}


/* Tag Cloud
-----------------------------------------------------------------*/

.tagcloud { margin-bottom: -2px;
	a {
		display: block;
		float: left;
		margin-right: 4px;
		margin-bottom: 4px;
		padding: 3px 6px;
		border: 1px solid darken($body-bg, 10.20);
		font-size: 13px !important;
		color: lighten($text-color, 13.33);
		border-radius: 2px;

		body:not(.device-touch) & {
			-webkit-transition: all .3s ease-in-out;
			-o-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
		}

		&:hover {
			border-color: $theme-color !important;
			color: $theme-color !important;
		}
	}
}