
/*-----------------------------------------------------------------------------------

	Shortcodes: faqs.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	FAQ List
-----------------------------------------------------------------*/

.faqlist {
	li {
		margin-bottom: 5px;
		font-size: 15px;
		a {
			color: #444;
			&:hover {
				color: $theme-color !important;
			}
		}
	}
}
